import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import axios from "axios";
import moment from "moment";
import Pagination from "react-js-pagination";

import { API_ROOT } from "gatsby-env-variables";

import Layout from "../components/academy-layout";
import NewNavbar from "../components/navbar";
import ArticlesList from "../components/general/articles-list";
import styles from "../styles/pages/jobs.module.scss";
import protocolaiLogo from "../assets/images/news/protocolai-logo.jpg";

import "../styles/pages/news.scss";

const News = () => {
  const [newsList, setNewsList] = useState([]);

  useEffect(() => {
    getNewsList();
  }, []);

  const getNewsList = () => {
    axios.get(`${API_ROOT}/api/v2/posts?type=news`).then((response) => {
      if (response?.data?.data) {
        setNewsList(response.data.data);
      }
    });
  };

  if (newsList.length) {
    console.log("moment", moment().format("MMM Do YYYY"));
  }
  return (
    <Layout>
      <NewNavbar />
      <div className={`news-page page-container`}>
        <Container>
          <div className="news-page__intro">
            <div className="news-page__intro_content">
              <p>News</p>
              <p>
                Immerse yourself in the fascinating world of clinical trials and
                AI-based technology company. Explore the latest news from
                Risklick and stay up-to-date with our upcoming events.
              </p>
            </div>
            <div className="news-page__intro_banner">
              <img src={require("../assets/images/news/news-cover@2x.webp")} />
            </div>
          </div>

          {!!newsList.length ? (
            <div className="news-page__featured">
              <p className="news-page__featured_title section_title">
                Articles
              </p>
              <Row className="news-page__featured_item">
                <Col
                  sm={{ span: 12 }}
                  lg={{ span: 6 }}
                  className={`${styles.jobs__items_banner}`}
                >
                  <img
                    src={
                      newsList[0].picture["190x160-fit"] ||
                      require("../assets/images/news/featured-article@2x.webp")
                    }
                    alt={newsList[0].title}
                  />
                  <img
                    className="news-details__image"
                    src={newsList[0].picture["190x160-fit"] || protocolaiLogo}
                    alt={newsDetails.title}
                  />
                </Col>
                <Col
                  sm={{ span: 12 }}
                  lg={{ span: 6 }}
                  className={styles.jobs__items_content}
                >
                  <p>{newsList[0].title}</p>
                  <p className="news-page__article_summary">
                    {newsList[0].short_body}
                  </p>
                  <p className="news-page__article_date">
                    {moment(newsList[0].created_at).format("MMM Do YYYY")}
                  </p>
                  <Link
                    to={`/news/${newsList[0].slug}`}
                    className={`section_cta ${styles.jobs__items_cta}`}
                  >
                    Read More
                  </Link>
                </Col>
              </Row>
            </div>
          ) : null}

          {/*  <div className="new-page__articles_list">
            <ArticlesList articles={newsList} />
          </div> */}
        </Container>
      </div>
    </Layout>
  );
};

export default News;
