import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import moment from "moment";

import "./style.scss";

const ArticlesList = (props) => {
  const { articles } = props;

  return (
    <div className="articles-list">
      <Row>
        {!!articles.length &&
          articles.map((item, index) => {
            const isTopRounded = index % 3 != 1;
            console.log("item", item);
            return (
              <Col key={index} sm={{ span: 12 }} lg={{ span: 4 }}>
                <Link
                  to={`/news/${item.slug}`}
                  className={`articles-list_item ${
                    isTopRounded ? "top_round" : "bottom_round"
                  }`}
                >
                  {!!isTopRounded && (
                    <img
                      className="articles-list_image"
                      src={
                        item.picture["190x160-fit"] ||
                        require("../../../assets/images/news/article-sample.jpeg")
                      }
                      alt={item.title}
                    />
                  )}
                  <div className="articles-list_content">
                    <p className="articles-list_title">{item.title}</p>
                    <p className="articles-list_summary">{item.short_body}</p>
                    <p className="articles-list_date">
                      {moment(item.date).format("MMM Do YYYY")}
                    </p>
                  </div>
                  {!isTopRounded && (
                    <img
                      className="articles-list_image"
                      src={
                        item.picture["190x160-fit"] ||
                        require("../../../assets/images/news/article-sample.jpeg")
                      }
                      alt={item.title}
                    />
                  )}
                </Link>
              </Col>
            );
          })}
      </Row>
    </div>
  );
};

export default ArticlesList;
